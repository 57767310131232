/* global wp, jQuery */

(function ($) {
    const mobileWidth = 767;
    const windowWidth = $(window).width();
    const currentPadding = +$('body').css('padding-top').replace("px", "");


    function windowFreeze() {
        $('body, html').css('overflow', 'hidden');
    }

    function windowUnFreeze() {
        $('body, html').css('overflow', 'visible');
    }

    const Header = function () {
        this.header = $('.site-header');
        this.init();
        this.closeMobileTopHeaderEvent();
    };

    Header.prototype.init = function () {
        if (this.header.length) {
            let that = this;
            let positionToScroll = 30;

            $(window).on('scroll', function () {
                if ($(this).scrollTop() >= positionToScroll) {
                    that.header.addClass('active');
                } else {
                    that.header.removeClass('active');
                }
            })
            $(window).trigger('scroll');
        }
    }
    Header.prototype.closeMobileTopHeaderEvent = function () {
        $('.site-header__mobile-top-header .close').on('click', function () {
            $('.site-header__mobile-top-header').remove();
            $('#page').removeClass('has-top-header');
            $('body').css('padding-top', currentPadding + 'px');
        })
    }
    new Header();

    if (windowWidth <= mobileWidth) {
        $('.newsletter-section__mobile-form')
            .append($('.newsletter-section__text'))
            .append($('.newsletter-section__form'));

        $('.newsletter-section__form').removeClass('d-none');

        // top section set yt video
        $('.home-page-top-section__mobile-video').append($('.home-page-top-section__yt'));
    }

    const InputForm = function () {
        this.inputs = $('.form-input');
        this.init();
    }

    InputForm.prototype.init = function () {
        if (this.inputs) {
            this.inputs.on('click', function () {
                $(this).addClass('active');
                $('input', this).focus();
            });

            $('input, select, textarea', this.inputs).on('focusin change', function () {
                let parent = $(this).parents('.form-input');
                parent.addClass('active');
                $('.form-input__select', parent).addClass('active');
            });

            $('input, select, textarea', this.inputs).on('focusout change', function () {
                let parent = $(this).parents('.form-input');

                if (!$(this).val()) {
                    parent.removeClass('active');
                }

                $('.form-input__select', parent).removeClass('active');
            })

            this.inputs.each(function () {
                if ($('input, select, textarea', this).val()) {
                    $(this).addClass('active');
                }
            })

            setInterval(function () {
                $('.form-input').each(function () {
                    if ($('input, select, textarea', this).val()) {
                        $(this).addClass('active');
                    }
                })
            }, 1000);

            this.passwordVisibility();
        }
    }
    InputForm.prototype.passwordVisibility = function () {
        let passwordVisibility = $('.password-visibility');
        if (passwordVisibility.length > 0) {
            passwordVisibility.on('click', function () {
                let parentInput = $(this).parents('.form-input');
                $(this).toggleClass('hide-password');

                if ($(this).hasClass('hide-password')) {
                    $('input[type="text"]', parentInput).attr('type', 'password');
                } else {
                    $('input[type="password"]', parentInput).attr('type', 'text');
                }
            })
        }
    }
    new InputForm();

    const LogotypeSlider = function () {
        this.carousel = $('.logotype-slider-section__items');
        this.init();
    }

    LogotypeSlider.prototype.init = function () {
        if (this.carousel) {
            let options = {
                slidesToShow: 5,
                slidesToScroll: 1,
                arrows: false,
                dots: false,
                responsive: null
            }

            if (this.carousel.hasClass('mc-brands__logotypes')) {
                options.responsive = [
                    {
                        breakpoint: 768,
                        settings: 'unslick'
                    }
                ];
            } else {
                options.responsive = [
                    {
                        breakpoint: 768,
                        settings: {
                            slidesToShow: 2
                        }
                    }
                ];

            }

            this.carousel.slick(options);
        }
    }
    new LogotypeSlider();


    const ProductCarousel = function () {
        this.carousel = $('.products-section__items');
        this.init();
    };

    ProductCarousel.prototype.init = function () {
        if (this.carousel.length > 0) {
            this.carousel.slick({
                slidesToShow: 2,
                slidesToScroll: 1,
                arrows: true,
                dots: false,
                centerMode: true,
                centerPadding: '16rem',
                prevArrow: $('.products-section__arrow-prev'),
                nextArrow: $('.products-section__arrow-next'),
                responsive: [
                    {
                        breakpoint: 768,
                        settings: {
                            arrows: false,
                            centerPadding: '6rem',
                            slidesToShow: 1,
                        }
                    }
                ]
            });
        }
    }

    new ProductCarousel();

    const SpecialAction = function () {
        this.action = $('.special-action');
        this.init();
    }

    SpecialAction.prototype.init = function () {
        if (this.action.length > 0) {
            let that = this;

            $('.special-action__flame').on('click', function (e) {
                if (!that.action.hasClass('active')) {
                    that.action.addClass('active');
                }
            })

            $('.special-action__close').on('click', function () {
                if (that.action.hasClass('active')) {
                    that.action.removeClass('active');
                }
            })
        }
    }

    new SpecialAction();

    const MainMenu = function () {
        this.menu = $('.main-navigation');
        this.init();
    };

    MainMenu.prototype.init = function () {
        if (this.menu && windowWidth <= 1199) {
            this.hamburger();
            this.addArrows();
        }
    }

    MainMenu.prototype.hamburger = function () {
        let that = this;

        $('.js-hamburger').on('click', function () {
            that.menu.addClass('active');
        })

        $('.site-header .close').on('click', function () {
            that.menu.removeClass('active');
        })
    }
    MainMenu.prototype.addArrows = function () {
        const arrow = '<span class="toggle-sub-menu"><svg xmlns="http://www.w3.org/2000/svg" width="15.556" height="15.556" viewBox="0 0 15.556 15.556">\n' +
            '  <g id="Component_292_2" data-name="Component 292 – 2" transform="translate(0.707)">\n' +
            '    <path id="Path_1681" data-name="Path 1681" d="M0,0H10V10" transform="translate(14.142 7.071) rotate(135)" fill="none" stroke="#373639" stroke-width="2"/>\n' +
            '  </g>\n' +
            '</svg></span>'
        $('.menu-item-has-children > a').each(function () {
            $(this).append(arrow);
        })

        $('.toggle-sub-menu').on('click', function (e) {
            e.stopPropagation();
            e.preventDefault();
            let parentNode = $(this).parents('li');

            $('> .sub-menu', parentNode).toggleClass('active');
            $(this).toggleClass('active');
        })
    }

    new MainMenu();

    const ContactForm = function () {
        this.form = $('.form-section form');
        this.init();
    }

    ContactForm.prototype.init = function () {
        if (this.form.length > 0) {
            this.prepareCheckboxInput();
        }
    }
    ContactForm.prototype.prepareCheckboxInput = function () {
        $('.pretty').prepend($('#webform_consent'));
        $('.pretty .wpcf7-form-control-wrap').remove();
    }

    new ContactForm();

    const ProductOrdering = function () {
        this.orderingBox = $('.product-filter-ordering');
        this.wcOrderingForm = $('.woocommerce-ordering');
        this.init();
    };

    ProductOrdering.prototype.init = function () {
        if (this.orderingBox.length > 0) {
            this.wcOrderingForm.addClass('d-none');
            // this.prepareSelectedValueLabel();
            this.prepareBoxValues();
            this.selectOrderEvent();
        }
    }

    ProductOrdering.prototype.prepareBoxValues = function () {
        let that = this;

        $('.orderby option', this.wcOrderingForm).each(function () {
            $('.product-filter__value', that.orderingBox).append('<p class="product-filter__option" data-orderby="' + $(this).val() + '">' + $(this).text() + '</p>');
        })
    }

    ProductOrdering.prototype.selectOrderEvent = function () {
        let that = this;

        $('.product-filter__option', this.orderingBox).on('click', function () {
            that.orderingBox.removeClass('active');
            $('.orderby option[value="' + $(this).data('orderby') + '"]', that.wcOrderingForm).prop("selected", true);
            $('.orderby', that.wcOrderingForm).trigger('change');
        })
    }

    new ProductOrdering();

    const ProductFilter = function () {
        this.filter = $('.product-filter');
        this.init();
    }

    ProductFilter.prototype.init = function () {
        if (this.filter.length > 0) {
            $('.product-filter__label', this.filter).on('click', function () {
                let filterBox = $(this).parents('.product-filter');
                filterBox.toggleClass('active');

                $('.product-filter').not(filterBox).removeClass('active');

                if (windowWidth <= mobileWidth) {
                    $('.mobile-screen-curtain').addClass('active');
                    windowFreeze();
                }

                if (filterBox.hasClass('product-filter-mobile')) {
                    $('.product-filters').toggleClass('active');
                }
            })

            this.prepareSelectedFiltersCountAndEvent();
            this.closeButtonMobile();
            this.preparePriceSlider();
        }
    }

    ProductFilter.prototype.closeButtonMobile = function () {
        if (windowWidth <= mobileWidth) {
            $('.product-filter-close').on('click', function () {
                let filterBox = $(this).parents('.product-filter');

                if (filterBox.length === 0) {
                    filterBox = $(this).parents('.product-filters');
                }

                filterBox.removeClass('active');

                $('.mobile-screen-curtain').removeClass('active');
                $('.product-filter-mobile').removeClass('active');


                windowUnFreeze();
            })
        }
    }

    ProductFilter.prototype.prepareSelectedFiltersCountAndEvent = function () {
        let mainThat = this;

        $('.product-filter__value--with-checkboxes').each(function () {
            let that = this;

            let waitForInput = setInterval(function () {
                if ($('.wc-block-components-checkbox__input', that).length > 0) {
                    let mainCounter = 0;

                    mainThat.filter.each(function () {
                        let count = $('input[type=checkbox]:checked', this).length;

                        if (count > 0) {
                            $('.counter', this).text(' (' + count + ')');

                            mainCounter += count;
                        }
                    })

                    if (mainCounter > 0) {
                        $('.global-counter').text(' (' + mainCounter + ')');
                    }

                    $('.wc-block-components-checkbox__input').on('change', function () {
                        let parentFilter = $(this).parents('.product-filter');
                        let countOfSelectedFilters = $('input[type=checkbox]:checked', parentFilter).length;

                        if (countOfSelectedFilters > 0) {
                            $('.counter', parentFilter).text(' (' + countOfSelectedFilters + ')');
                        } else {
                            $('.counter', parentFilter).text('');
                        }

                        let waitForResetButton = setInterval(function () {
                            if ($('.wc-block-components-filter-reset-button', that).length > 0) {
                                $('.wc-block-components-filter-reset-button').on('click', function () {
                                    let parentFilter = $(this).parents('.product-filter');
                                    $('.counter', parentFilter).text('');
                                })

                                clearInterval(waitForResetButton);
                            }
                        })
                    })

                    let waitForResetButton = setInterval(function () {
                        if ($('.wc-block-components-filter-reset-button', that).length > 0) {
                            $('.wc-block-components-filter-reset-button').on('click', function () {
                                let parentFilter = $(this).parents('.product-filter');
                                $('.counter', parentFilter).text('');
                            })

                            clearInterval(waitForResetButton);
                        }
                    })

                    clearInterval(waitForInput);
                }
            }, 100)
        })
    }

    ProductFilter.prototype.preparePriceSlider = function () {
        let priceFilter = $('.product-filter-price');

        $('.product-filter__value', priceFilter).prepend('<div class="product-filter__controls"><div>-</div></div>');

        if (priceFilter.length > 0) {
            let waitForInputs = setInterval(function () {
                if ($('.wc-block-formatted-money-amount', priceFilter).length > 0) {
                    $('.product-filter__controls', priceFilter).prepend($('.wc-block-price-filter__amount--min', priceFilter));
                    $('.product-filter__controls', priceFilter).append($('.wc-block-price-filter__amount--max', priceFilter));

                    $('.wc-block-price-filter__controls', priceFilter).append('<div class="product-filter__min">' + $('.wc-block-price-filter__amount--min').val() + '</div>')
                    $('.wc-block-price-filter__controls', priceFilter).append('<div class="product-filter__max">' + $('.wc-block-price-filter__amount--max').val() + '</div>')

                    // min
                    $('.wc-block-components-price-slider__range-input--min', priceFilter).on('change input', function () {
                        $('.product-filter__min', priceFilter).text($('.wc-block-price-filter__amount--min').val());
                    })

                    $('.wc-block-price-filter__amount--min', priceFilter).on('change input', function () {
                        $('.product-filter__min', priceFilter).text($(this).val());
                    })

                    // max
                    $('.wc-block-components-price-slider__range-input--max', priceFilter).on('change input', function () {
                        $('.product-filter__max', priceFilter).text($('.wc-block-price-filter__amount--max').val());
                    })

                    $('.wc-block-price-filter__amount--max', priceFilter).on('change input', function () {
                        $('.product-filter__max', priceFilter).text($(this).val());
                    })

                    clearInterval(waitForInputs);
                }
            })
        }
    }

    new ProductFilter();

    const ProductPagination = function () {
        this.pagination = $('.woocommerce-pagination');
        this.init();
    }

    ProductPagination.prototype.init = function () {
        if (this.pagination.length > 0) {
            let currentPage = $('.current-page', this.pagination).val();
            let totalPages = $('.total-pages', this.pagination).val();

            $('.page-numbers:not(.prev):not(.next)').remove();

            if ($('.prev', this.pagination).length > 0) {
                $('.prev', this.pagination).after('<span class="page-numbers-info">Strona ' + currentPage + ' z ' + totalPages + '</span>')
            } else {
                this.pagination.prepend('<span class="page-numbers-info">Strona ' + currentPage + ' z ' + totalPages + '</span>')
            }

        }
    }

    new ProductPagination();

    const ProductBestSellingSlider = function () {
        this.carousel = $('.best-selling__slider');
        this.init();
    }

    ProductBestSellingSlider.prototype.init = function () {
        if (this.carousel) {
            this.carousel.slick({
                slidesToShow: 4,
                slidesToScroll: 1,
                arrows: false,
                dots: false,
                centerMode: true,
                centerPadding: '100px',
                responsive: [
                    {
                        breakpoint: 768,
                        settings: {
                            slidesToShow: 1,
                            centerPadding: '80px',
                        }
                    }
                ]
            });
        }
    }
    new ProductBestSellingSlider();

    const PressRoomImageMasonry = function () {
        this.masonry = $('.download-section__images');
        this.init();
    }

    PressRoomImageMasonry.prototype.init = function () {
        if (this.masonry.length > 0) {
            let that = this;

            this.masonry = $('.download-section__images').imagesLoaded(function () {
                that.masonry.masonry({
                    itemSelector: '.download-section__image',
                    columnWidth: '.download-section__image',
                    gutter: '.download-section__gutter',
                    horizontalOrder: true,
                    percentPosition: true
                });
            });
        }
    }

    new PressRoomImageMasonry();

    const ProductRelatedSlider = function () {
        this.carousel = $('.related__slider');
        this.init();
    }

    ProductRelatedSlider.prototype.init = function () {
        if (windowWidth > mobileWidth) {
            if (this.carousel && $('.product', this.carousel).length >= 4) {
                this.initSlick();
            }
        } else {
            if (this.carousel && $('.product', this.carousel).length >= 2) {
                this.initSlick();
            }
        }
    }

    ProductRelatedSlider.prototype.initSlick = function () {
        this.carousel.slick({
            slidesToShow: 4,
            slidesToScroll: 1,
            arrows: false,
            dots: false,
            centerMode: true,
            centerPadding: '100px',
            responsive: [
                {
                    breakpoint: 768,
                    settings: {
                        slidesToShow: 1,
                        centerPadding: '80px',
                    }
                }
            ]
        });
    }
    new ProductRelatedSlider();

    const ProductPriceBar = function () {
        this.bar = $('.product-bar-add-to-cart');
        this.init();
    };

    ProductPriceBar.prototype.init = function () {
        if (this.bar.length) {
            let that = this;
            let actionButton = $('.summary .product_price');

            if (actionButton.length === 0) {
                actionButton = $('.summary .btn-profiart');
            }

            if (actionButton.length !== 0) {
                let positionToScroll = (actionButton.offset().top + actionButton.outerHeight());

                $(window).on('scroll', function () {
                    if ($(this).scrollTop() >= positionToScroll) {
                        that.bar.addClass('active');
                    } else {
                        that.bar.removeClass('active');
                    }
                })
                $(window).trigger('scroll');
            }
        }
    }

    new ProductPriceBar();

    const ProductAddToCart = function () {
        this.inputAddToCart = $('.event-add-to-cart');
        this.productId = 0;
        this.addedToCartModal = $('#addedToCart');
        this.init();
    }

    ProductAddToCart.prototype.init = function () {
        // on products list
        let that = this;

        $(document.body).on('added_to_cart', function (e, fragments, cart_hash, $thisbutton) {
            let currentProduct = $thisbutton.parents('.product');
            let thumbnail = $('.attachment-woocommerce_thumbnail', currentProduct);
            let title = $('.woocommerce-loop-product__title', currentProduct).text();
            let price = $('.price', currentProduct);
            this.productId = currentProduct.attr('data-product-id');

            $('.modal__product__thumbnail', that.addedToCartModal).html(thumbnail.clone());
            $('.modal__product__title', that.addedToCartModal).text(title);
            $('.modal__product__price', that.addedToCartModal).html(price.clone());

            that.updateOthersBoughtSection();

            that.addedToCartModal.modal('show');
        });

        // on single page - page reloaded - check if inuput has value
        if (this.inputAddToCart.length > 0 && this.inputAddToCart.val() == 1) {
            let that = this;
            this.productId = $('.single-product-page.product').attr('data-product-id');

            let waitForBootstrap = setInterval(function () {
                if (bootstrap) {
                    that.updateOthersBoughtSection();
                    that.addedToCartModal.modal('show');

                    clearInterval(waitForBootstrap);
                }
            }, 100)
        }
    }
    ProductAddToCart.prototype.updateOthersBoughtSection = function () {
        // delete the same product as bought
        let theSameProduct = $('.modal-other-bought__item[data-product-id=' + this.productId + ']', this.addedToCartModal);
        if (theSameProduct.length > 0) {
            $('.modal-other-bought__item[data-product-id=' + this.productId + ']', this.addedToCartModal).remove();
        } else {
            if ($('.modal-other-bought__item').length > 2) {
                $('.modal-other-bought__item:last-of-type', this.addedToCartModal).remove();
            }
        }
    }

    new ProductAddToCart();

    const ProductQuantity = function (isCheckoutPage = false) {
        this.quantity = $('.quantity');
        this.isCheckoutPage = isCheckoutPage;
        this.init();
    }

    ProductQuantity.prototype.init = function () {
        if (this.quantity.length > 0) {
            this.decrementEvent();
            this.incrementEvent();
        }
    }
    ProductQuantity.prototype.decrementEvent = function () {
        let that = this;

        $('.quantity__decrement').on('click', function () {
            let parentQuantity = $(this).parent('.quantity');
            let input = $('input', parentQuantity);
            let currentValue = parseInt(input.val());
            let minValue = parseInt(input.attr('min'));
            let step = parseInt(input.attr('step'));
            let newValue = (currentValue - step);

            if (newValue < minValue) {
                newValue = minValue;
            }

            input.val(newValue);

            $('button[name="update_cart"]').attr('aria-disabled', 'false');
            $('button[name="update_cart"]').removeAttr('disabled');

            $('button[name="update_cart"]').trigger('click');

            if (that.isCheckoutPage) {
                $(document.body).trigger('update_checkout');
            }
        })
    }
    ProductQuantity.prototype.incrementEvent = function () {
        let that = this;

        $('.quantity__increment').on('click', function () {
            let parentQuantity = $(this).parent('.quantity');

            let input = $('input', parentQuantity);
            let currentValue = parseInt(input.val());
            let maxValue = parseInt(input.attr('max'));
            let step = parseInt(input.attr('step'));
            let newValue = (currentValue + step);

            if (newValue > maxValue) {
                newValue = maxValue;
            }

            input.val(newValue);

            $('button[name="update_cart"]').attr('aria-disabled', 'false');
            $('button[name="update_cart"]').removeAttr('disabled');

            $('button[name="update_cart"]').trigger('click');

            if (that.isCheckoutPage) {
                $(document.body).trigger('update_checkout');
            }
        })
    }

    new ProductQuantity();

    const Checkout = function () {
        this.wcCheckout = $('.woocommerce-checkout');
        this.init();
    }
    Checkout.prototype.init = function () {
        if (this.wcCheckout.length > 0) {
            this.removeLogo();
            this.initUpsellGallery();
            this.initUpsellInputEvent();
            initCheckboxPrettyForDigitalAgreement();
            initCheckboxPrettyForVatFields();
            this.onUpdateCheckoutEvent();

            if ($('.remove-whole-checkout-page').length > 0) {
                $('.wc-box-content, .checkout.woocommerce-checkout').remove();
            }

            // fakturownia input NIP - add class
            $('.vat-number').addClass('form-input woo-checkout-company');

            $('.woo-checkout-company').addClass('d-none');
            $('#customer_details').addClass('not-show');
            $('.woocommerce-shipping-fields').addClass('d-none');

            $('input[name="wc_checkout_choose_type"]').on('change', function () {
                let value = $(this).val();

                if (value == 'private') {
                    $('.woo-checkout-company').addClass('d-none');
                    $('.woocommerce-shipping-fields').addClass('d-none');
                    $('#billing_shipping_header_field, #billing_country_field, #billing_address_1_field, #billing_postcode_field, #billing_city_field').removeClass('d-none');

                    $('#billing_order_type_field input').prop('checked', true);

                    $('.woo-checkout-company input').val('');
                    $('.woo-checkout-company').removeClass('active');
                } else {
                    $('.woo-checkout-company').removeClass('d-none');
                    $('.woocommerce-shipping-fields').removeClass('d-none');
                    $('#billing_shipping_header_field').addClass('d-none');

                    $('#billing_order_type_field input').prop('checked', false);
                }

                $('#customer_details').removeClass('not-show');
            });

            $('input[name="wc_checkout_choose_type"]:checked').trigger('change');

            // validate for nip
            // $('.site-main-section').on('blur change keyup', '#billing_nip', function () {
            //     // if is company order
            //     if (!$('#billing_order_type_field input').prop('checked')) {
            //         let value = $(this).val();
            //         $(this).val(value.toUpperCase()).val();
            //     }
            // });

            // on checkout form errors
            $(document.body).on('checkout_error', function () {
                new WCNotices();

                if (!$('#billing_order_type_field input').prop('checked')) {
                    let fields = ['billing_company', 'billing_nip', 'billing_company_street', 'billing_company_postcode', 'billing_company_city'];

                    fields.forEach(function (item) {
                        if ($('#' + item)) {
                            let value = $('#' + item).val();

                            if (value.length === 0) {
                                $('#' + item).parents('.form-row').addClass('woocommerce-invalid').removeClass('woocommerce-validated');
                            } else {
                                $('#' + item).parents('.form-row').addClass('woocommerce-validated').removeClass('woocommerce-invalid');
                            }
                        }
                    })
                }
            });
        }
    }

    Checkout.prototype.removeLogo = function () {
        $('.custom-logo-link').attr('href', '#');
        $('.mobile-logo').attr('href', '#');

        $('.custom-logo-link, .mobile-logo').on('click', function (e) {
            e.preventDefault();
            e.stopPropagation();
        })
    }

    Checkout.prototype.initUpsellInputEvent = function () {
        $('.upsell-checkbox').on('change', function () {
            let input = $(this);
            let wholeUpsellProduct = $(this).parents('.checkout-custom-upsell__product');
            let checkboxValue = input.prop('checked');

            $.ajax({
                url: upsell_script_ajax_object.ajax_url,
                method: 'post',
                dataType: 'json',
                data: {
                    action: 'upsell_buy_wc_item',
                    _ajax_nonce: upsell_script_ajax_object.ajax_nonce,
                    product_id: $('.product-upsell-id', wholeUpsellProduct).val(),
                    is_checked: checkboxValue
                },
                success: function (response) {
                    if (response.ok) {
                        if (!checkboxValue) {
                            location.reload();
                        } else {
                            $(document.body).trigger("update_checkout");
                        }

                        $('html, body').animate({
                            scrollTop: ($('#purple-tab-with-text').offset().top - 100)
                        }, 1000);
                    }
                },
                error: function () {
                    console.log('Something went wrong transform data - try again later.');
                }
            })
        })
    }

    Checkout.prototype.initUpsellGallery = function () {
        let masonryUpsell = $('.checkout-custom-upsell__reviews');

        if (masonryUpsell.length > 0) {
            masonryUpsell.imagesLoaded(function () {
                masonryUpsell.masonry({
                    itemSelector: '.checkout-custom-upsell__reviews__item',
                    columnWidth: '.checkout-custom-upsell__reviews__item',
                    gutter: '.checkout-custom-upsell__reviews__gutter',
                    percentPosition: true
                });
            });
        }
    }

    Checkout.prototype.onUpdateCheckoutEvent = function () {
        $(document.body).on("updated_checkout", function () {
            new ProductQuantity(true);
            new WCNotices();

            initCheckboxPrettyForDigitalAgreement();
            initCheckboxPrettyForVatFields();
        })
    }

    new Checkout();

    function initCheckboxPrettyForDigitalAgreement() {
        let waitForCheckbox = setInterval(function () {
            if ($('#consent_to_digital_content').length > 0) {
                setTimeout(function () {
                    $('#consent_to_digital_content').each(function () {
                        if ($(this).parents('.shop-page-template').length > 0) return;
                        if ($(this).parents('.pretty').length === 0) {
                            let input = $(this);
                            let parent = $(this).parent();
                            let label = parent.find('label');

                            if (parent.is('label')) {
                                label = parent;
                                parent = parent.parent();
                            }

                            let box = '<div class="form-checkbox"><div class="pretty p-svg"></div></div>';
                            parent.prepend(box);
                            let boxJQ = $('.pretty', parent);

                            boxJQ.append('<div class="state"><svg class="svg svg-icon" xmlns="http://www.w3.org/2000/svg" width="20.029"\n' +
                                '                                         height="16.495"\n' +
                                '                                         viewBox="0 0 20.029 16.495">\n' +
                                '                                        <g id="Component_6_19" data-name="Component 6 – 19"\n' +
                                '                                           transform="translate(1.125 0.967)">\n' +
                                '                                            <path id="Path_12" data-name="Path 12"\n' +
                                '                                                  d="M-445.086,556l6.6,7.479,11.161-13.232"\n' +
                                '                                                  transform="translate(445.086 -550.247)" fill="none" stroke="#373639"\n' +
                                '                                                  stroke-width="3"/>\n' +
                                '                                        </g>\n' +
                                '                                    </svg></div>')
                            boxJQ.prepend(input);
                            boxJQ.find('.state').append(label);
                        }
                    })
                }, 2000);

                clearInterval(waitForCheckbox);
            }
        }, 100)
    }

    // VAT additional fields like 'Jestem juz zar. w VAT EU lub mam siedzibe'
    function initCheckboxPrettyForVatFields() {
        let waitForCheckbox2 = setInterval(function () {
            if ($('.wc-checkout-buttons .terms input').length > 0) {
                setTimeout(function () {
                    $('.wc-checkout-buttons .terms input').each(function () {
                        if ($(this).parents('.shop-page-template').length > 0) return;
                        if ($(this).parents('.pretty').length === 0) {
                            let input = $(this);
                            let parent = $(this).parent();
                            let label = parent.find('label');

                            if (parent.is('label')) {
                                label = parent;
                                parent = parent.parent();
                            }

                            let box = '<div class="form-checkbox"><div class="pretty p-svg"></div></div>';
                            parent.prepend(box);
                            let boxJQ = $('.pretty', parent);

                            boxJQ.append('<div class="state"><svg class="svg svg-icon" xmlns="http://www.w3.org/2000/svg" width="20.029"\n' +
                                '                                         height="16.495"\n' +
                                '                                         viewBox="0 0 20.029 16.495">\n' +
                                '                                        <g id="Component_6_19" data-name="Component 6 – 19"\n' +
                                '                                           transform="translate(1.125 0.967)">\n' +
                                '                                            <path id="Path_12" data-name="Path 12"\n' +
                                '                                                  d="M-445.086,556l6.6,7.479,11.161-13.232"\n' +
                                '                                                  transform="translate(445.086 -550.247)" fill="none" stroke="#373639"\n' +
                                '                                                  stroke-width="3"/>\n' +
                                '                                        </g>\n' +
                                '                                    </svg></div>')
                            boxJQ.prepend(input);
                            boxJQ.find('.state').append(label);
                        }
                    })
                }, 2000);

                clearInterval(waitForCheckbox2);
            }
        }, 100)
    }

    let collapseFormArea = $('#collapseFormArea');
    if (collapseFormArea.length > 0) {
        $('#collapseLoginForm')
            .on('show.bs.collapse', function () {
                $('.woocommerce-checkout__account-form__details__open-login').addClass('d-none');
                $('.woocommerce-checkout__account-form__details__close-login').removeClass('d-none');
            })
            .on('hide.bs.collapse', function () {
                $('.woocommerce-checkout__account-form__details__open-login').removeClass('d-none');
                $('.woocommerce-checkout__account-form__details__close-login').addClass('d-none');
            })

        $('#collapseRegisterForm')
            .on('show.bs.collapse', function () {
                $('.woocommerce-checkout__account-form__details__open-register').addClass('d-none');
                $('.woocommerce-checkout__account-form__details__close-register').removeClass('d-none');
            })
            .on('hide.bs.collapse', function () {
                $('.woocommerce-checkout__account-form__details__open-register').removeClass('d-none');
                $('.woocommerce-checkout__account-form__details__close-register').addClass('d-none');
            })
    }

    $('.woocommerce-MyAccount-navigation__select').on('click', function () {
        $('~ul', this).toggleClass('active');
        $(this).toggleClass('active');
    })

    const ProductSaleTimer = function () {
        this.timer = $('.sale-on-date:not(.dz-banner-timer)');
        this.init();
    };
    ProductSaleTimer.prototype.init = function () {
        if (this.timer.length > 0) {
            this.startCountDownDate();
            $('body').addClass('wc-sale-on-date');
        }
    }
    ProductSaleTimer.prototype.startCountDownDate = function () {
        const countDownValue = $('input[name="count-down-date"]').val();
        const countDownDate = new Date(countDownValue).getTime();
        const that = this;

        let x = setInterval(function () {
            // Get today's date and time
            let now = new Date().getTime();

            // Find the distance between now and the count down date
            let distance = countDownDate - now;

            // Time calculations for days, hours, minutes and seconds
            let days = Math.floor(distance / (1000 * 60 * 60 * 24));
            let hours = Math.floor((distance % (1000 * 60 * 60 * 24)) / (1000 * 60 * 60));
            let minutes = Math.floor((distance % (1000 * 60 * 60)) / (1000 * 60));
            let seconds = Math.floor((distance % (1000 * 60)) / 1000);

            // If the count down is over, write some text
            if (distance < 0) {
                clearInterval(x);
                $('.sale-on-date').remove();
                // remove this section for landing calendar
                $('.landing-calendar-product__timer').remove();

                setTimeout(function () {
                    location.reload();
                }, 1000);
            }

            $('.days .timer-box', that.timer).text(days);
            if (days === 1) {
                $('.days .timer-text').text('dzień');
            } else {
                $('.days .timer-text').text('dni');
            }

            $('.hours', that.timer).text(hours);
            $('.minutes', that.timer).text(minutes);
            $('.seconds', that.timer).text(seconds);
        }, 1000);
    }

    new ProductSaleTimer();

    const BlogPostsMasonry = function () {
        this.masonry = $('.blog-page__posts');
        this.init();
    }

    BlogPostsMasonry.prototype.init = function () {
        if (this.masonry.length > 0 && windowWidth > mobileWidth) {
            let that = this;

            this.masonry = $('.blog-page__posts').imagesLoaded(function () {
                that.masonry.masonry({
                    itemSelector: '.single-post-cart',
                    columnWidth: '.single-post-cart',
                    gutter: '.blog-page__posts-gutter',
                    horizontalOrder: true,
                    percentPosition: true
                });
            });
        }
    }

    new BlogPostsMasonry();
    const BlogPagination = function () {
        this.paginationBox = $('.blog-pagination');
        this.pagination = $('.nav-links', this.paginationBox);
        this.init();
    }

    BlogPagination.prototype.init = function () {
        if (this.pagination.length > 0) {
            let currentPage = $('.current-page', this.paginationBox).val();
            let totalPages = $('.total-pages', this.paginationBox).val();

            $('.page-numbers:not(.prev):not(.next)').remove();

            if ($('.prev', this.pagination).length > 0) {
                $('.prev', this.pagination).after('<span class="page-numbers-info">Strona ' + currentPage + ' z ' + totalPages + '</span>')
            } else {
                this.pagination.prepend('<span class="page-numbers-info">Strona ' + currentPage + ' z ' + totalPages + '</span>')
            }
        }
    }

    new BlogPagination();

    const Blog = function () {
        this.blogPage = $('.blog-page');
        this.init();
    }

    Blog.prototype.init = function () {
        if (this.blogPage.length > 0) {
            this.menuCategories();
        }
    }

    Blog.prototype.menuCategories = function () {
        if (windowWidth <= mobileWidth) {
            $('.blog-categories-mobile__switch').on('click', function () {
                $(this).parents('.blog-categories-mobile').toggleClass('active');
            })
        }
    }

    new Blog();

    const WCNotices = function () {
        this.noticesWrapper = $('.woocommerce-notices-wrapper');
        this.noticeModal = $('#noticeModal');
        this.init();
    }

    WCNotices.prototype.init = function () {
        let that = this;

        if (this.noticeModal.length) {
            let waitForBootstrap = setInterval(function () {
                if (bootstrap) {
                    that.noticeModal.modal('show');
                    $('.modal-backdrop').addClass('wc-notice-shadow');

                    that.noticeModal.on("hidden.bs.modal", function () {
                        $(this).remove();
                    });

                    // setTimeout(function () {
                    //     that.noticeModal.modal('hide');
                    // }, 3000);

                    clearInterval(waitForBootstrap);
                }
            }, 100)
        }
    }

    // WC Events
    // Checkout page
    // $(document.body).on('applied_coupon_in_checkout', function () {
    //     new WCNotices();
    //     couponInputEvent();
    // });
    // $(document.body).on('removed_coupon_in_checkout', function () {
    //     new WCNotices();
    //     couponInputEvent();
    // });

    // Cart page
    // $(document.body).on('applied_coupon', function () {
    //     new WCNotices();
    //     couponInputEvent();
    // });
    //
    // $(document.body).on('removed_coupon', function () {
    //     new WCNotices();
    //     couponInputEvent();
    // });

    $(document.body).on('added_to_cart', function () {
        const basketControl = $('.basket-control');
        const productCount = $('.control-counter-label', basketControl);

        productCount.text(parseInt($('.control-counter-label', basketControl).text()) + 1);
    })

    // WooCommerce events
    $(document.body).on('updated_cart_totals', function () {
        new ProductQuantity();

        new ProductSaleTimer();

        // remove Cart updated text in notices section.
        $('.woocommerce-notices-wrapper > .woocommerce-message').remove();

        // update coupon
        // let waitForInput = setInterval(function () {
        //     let couponDiv = $('.coupon')
        //     if ($('.form-input input[name="coupon_code"]').length > 0) {
        //         couponInputEvent()
        //
        //         $('.form-input', couponDiv).on('click', function () {
        //             $(this).addClass('active');
        //             $('input', this).focus();
        //         });
        //
        //         $('input[name="coupon_code"]', couponDiv).on('focusin', function () {
        //             let parent = $(this).parents('.form-input');
        //             parent.addClass('active');
        //             $('.form-input__select', parent).addClass('active');
        //         });
        //
        //         $('input[name="coupon_code"]', couponDiv).on('focusout change', function () {
        //             let parent = $(this).parents('.form-input');
        //
        //             if (!$(this).val()) {
        //                 parent.removeClass('active');
        //             }
        //
        //             $('.form-input__select', parent).removeClass('active');
        //         })
        //
        //         if ($('input[name="coupon_code"]').val()) {
        //             let parent = $(this).parents('.form-input');
        //             parent.addClass('active');
        //         }
        //
        //         clearInterval(waitForInput);
        //     }
        // }, 100)
    });

    function couponInputEvent() {
        $('.form-input input[name="coupon_code"]').on('keyup', function () {
            let buttonToApplyCoupon = $(this).parents('.coupon').find('button');

            if (buttonToApplyCoupon.length == 0) {
                buttonToApplyCoupon = $(this).parents('form').find('button');
            }

            if ($(this).val().length > 0) {
                buttonToApplyCoupon.addClass('active');
            } else {
                buttonToApplyCoupon.removeClass('active');
            }
        })
    }

    // couponInputEvent();

    const Publications = function () {
        this.publicationSection = $('.why-me-publication-section');
        this.init();
    }

    Publications.prototype.init = function () {
        if (this.publicationSection.length > 0) {
            $('.show-more', this.publicationSection).on('click', function (e) {
                e.preventDefault();
                let counter = 1;

                $('.why-me-publication-section__item.d-none').each(function () {
                    if (counter <= $('.items-to-show', this.publicationSection).val()) {
                        $(this).removeClass('d-none');
                        counter++;
                    }
                })

                if ($('.why-me-publication-section__item.d-none').length === 0) {
                    $(this).remove();
                }
            })
        }
    }
    new Publications();

    const Podcasts = function () {
        this.podcastSection = $('.why-me-podcast-section');
        this.init();
    }

    Podcasts.prototype.init = function () {
        if (this.podcastSection.length > 0) {
            $('.show-more', this.podcastSection).on('click', function (e) {
                e.preventDefault();
                let counter = 1;

                $('.why-me-podcast-section__item.d-none').each(function () {
                    if (counter <= $('.items-to-show', this.podcastSection).val()) {
                        $(this).removeClass('d-none');
                        counter++;
                    }
                })

                if ($('.why-me-podcast-section__item.d-none').length === 0) {
                    $(this).remove();
                }
            })
        }
    }
    new Podcasts();

    const Navigation = function () {
        this.init();
    }

    Navigation.prototype.init = function () {
        this.linkAnchorEvent();
    }

    Navigation.prototype.linkAnchorEvent = function () {
        let headerHeight = $('.site-header').outerHeight();

        $('.custom-anchor a, .custom-anchor-link').on('click', function (e) {
            e.stopPropagation()
            e.preventDefault()
            let anchor = $(this).attr('href');
            let existingSection = $(anchor);
            let pathName = location.pathname;

            if (existingSection.length === 0) {
                if (pathName != '/') {
                    location.href = '/' + anchor;
                    return;
                }
            }

            $('.js-hamburger').removeClass('is-active');
            $('.main-navigation').removeClass('active');
            $('body, html').removeClass('overflow-hidden');

            $('html, body').animate({
                scrollTop: ($(anchor).offset().top - headerHeight)
            }, 1000);
        })
    }
    new Navigation();

    const Books = function () {
        this.bookSection = $('.why-me-book-section');
        this.init();
    }

    Books.prototype.init = function () {
        if (this.bookSection.length > 0) {
            $('.show-more', this.bookSection).on('click', function (e) {
                e.preventDefault();
                let counter = 1;

                $('.why-me-book-section__item.d-none').each(function () {
                    if (counter <= $('.items-to-show', this.bookSection).val()) {
                        $(this).removeClass('d-none');
                        counter++;
                    }
                })

                if ($('.why-me-book-section__item.d-none').length === 0) {
                    $(this).remove();
                }
            })
        }
    }
    new Books();

    const FreebiesImageMasonry = function () {
        this.isMasonryInitied = false;
        this.masonry = $('.gallery-with-msg-section__images');
        this.init();
    }

    FreebiesImageMasonry.prototype.init = function () {
        if (this.masonry.length > 0 && windowWidth > mobileWidth) {
            let that = this;

            this.masonry = $('.gallery-with-msg-section__images').imagesLoaded(function () {
                that.masonry.masonry({
                    itemSelector: '.gallery-with-msg-section__image',
                    columnWidth: '.gallery-with-msg-section__image',
                    gutter: '.gallery-with-msg-section__gutter',
                    percentPosition: true
                });
            });

            this.isMasonryInitied = true;
        }

        this.loadMore();

    }
    FreebiesImageMasonry.prototype.loadMore = function () {
        let that = this;

        $('.gallery-with-msg-section .show-more').on('click', function (e) {
            e.preventDefault();

            let numberOfMessagesToLoad = $('input[name="message-to-load"]').val();
            let numberOfMessagesLeftToLoad = $('.gallery-with-msg-section__image.d-none').length;
            let imagesToLoad = $('.gallery-with-msg-section__image.d-none:lt(' + numberOfMessagesToLoad + ')');

            if (numberOfMessagesLeftToLoad <= imagesToLoad.length) {
                $(this).remove();
            }

            imagesToLoad.removeClass('d-none');

            if (that.isMasonryInitied) {
                that.masonry.masonry();
            }
        })
    }

    new FreebiesImageMasonry();

    const ProductCalendarSlider = function () {
        this.carousel = $('.product-calendar__months');
        this.init();
    }

    ProductCalendarSlider.prototype.init = function () {
        if (this.carousel) {
            this.carousel.slick({
                infinite: false,
                slidesToShow: 1,
                slidesToScroll: 1,
                arrows: true,
                dots: false,
                prevArrow: $('.product-calendar__arrow-prev'),
                nextArrow: $('.product-calendar__arrow-next'),
                adaptiveHeight: true
            });

            // Set current month and year - calendar title
            this.carousel.on('beforeChange', function (event, slick, currentSlide, nextSlide) {
                let activeSlide = $(slick.$slides[nextSlide]);

                $('.product-calendar__year').text(activeSlide.attr('data-month') + ' ' + activeSlide.attr('data-year'));
            });
        }
    }
    new ProductCalendarSlider();

    const ProductCalendar = function () {
        this.calendar = $('.product-calendar');
        this.init();
    }

    ProductCalendar.prototype.init = function () {
        if (this.calendar) {
            $('.product-calendar__month__day.has-events').on('click', function () {
                $('.product-calendar__month__day.has-events').removeClass('active');
                $(this).addClass('active');
                $('.product-calendar__time-available__item').remove();

                let events = JSON.parse($(this).attr('data-events'));
                $('.product-calendar__time-available__title').text($(this).attr('data-day-name') + ', ' + $(this).attr('data-day-number') + ' ' + $(this).attr('data-month-name'));

                events.forEach(element => $('.product-calendar__time-available__items').append('<div class="product-calendar__time-available__item">' + element + '</div>'));

                $('.product-calendar__time-available').addClass('active');
                $('.product-calendar__months').slick('refresh');
            });
        }
    }
    new ProductCalendar();

    const OutOfStockModal = function () {
        this.init();
    }

    OutOfStockModal.prototype.init = function () {
        $('.cwg_popup_submit').on('click', function () {
            let waitForInputs = setInterval(function () {
                if ($('.swal2-container .form-input').length > 0) {
                    let inputs = $('.swal2-container .form-input');
                    inputs.on('click', function () {
                        $(this).addClass('active');
                        $('input', this).focus();
                    });

                    $('input, select, textarea', inputs).on('focusin', function () {
                        let parent = $(this).parents('.form-input');
                        parent.addClass('active');
                        $('.form-input__select', parent).addClass('active');
                    });

                    $('input, select, textarea', inputs).on('focusout change', function () {
                        let parent = $(this).parents('.form-input');

                        if (!$(this).val()) {
                            parent.removeClass('active');
                        }

                        $('.form-input__select', parent).removeClass('active');
                    })

                    inputs.each(function () {
                        if ($('input, select, textarea', this).val()) {
                            $(this).addClass('active');
                        }
                    })

                    prepareCheckboxInputs();

                    clearInterval(waitForInputs);
                }
            }, 200);
        })
    }

    new OutOfStockModal();

    function prepareCheckboxInputs() {
        $('.swal2-container .cwg_iagree_checkbox input[type="checkbox"]').each(function () {
            if ($(this).parents('.shop-page-template').length > 0) return;
            if ($(this).parents('.pretty').length === 0) {
                let input = $(this);
                let parent = $(this).parent();
                let label = parent.find('label');

                if (parent.is('label')) {
                    label = parent;
                    parent = parent.parent();
                }

                let box = '<div class="form-checkbox"><div class="pretty p-svg"></div></div>';
                parent.prepend(box);
                let boxJQ = $('.pretty', parent);

                boxJQ.append('<div class="state"><svg class="svg svg-icon" xmlns="http://www.w3.org/2000/svg" width="20.029"\n' +
                    '                                         height="16.495"\n' +
                    '                                         viewBox="0 0 20.029 16.495">\n' +
                    '                                        <g id="Component_6_19" data-name="Component 6 – 19"\n' +
                    '                                           transform="translate(1.125 0.967)">\n' +
                    '                                            <path id="Path_12" data-name="Path 12"\n' +
                    '                                                  d="M-445.086,556l6.6,7.479,11.161-13.232"\n' +
                    '                                                  transform="translate(445.086 -550.247)" fill="none" stroke="#373639"\n' +
                    '                                                  stroke-width="3"/>\n' +
                    '                                        </g>\n' +
                    '                                    </svg></div>')
                boxJQ.prepend(input);
                boxJQ.find('.state').append(label);
            }
        })
    }


    function prepareBodyTopPadding() {
        if (windowWidth <= mobileWidth) {
            setTimeout(function () {
                if ($('.site-header__mobile-top-header p').length > 0) {
                    const lineHeight = +$('.site-header__mobile-top-header p').css('line-height').replace("px", "");
                    const height = +$('.site-header__mobile-top-header p').height();

                    let numberOfLines = (height / lineHeight);
                    let numberOfPxToAdd = 8;

                    if (numberOfLines >= 4) {
                        $('body').css('padding-top', (currentPadding + (numberOfPxToAdd * numberOfLines)) + 'px');
                    }
                }
            }, 500);
        }
    }

    prepareBodyTopPadding();

    function prepareHeaderWithObjectHeight() {
        if (windowWidth <= mobileWidth) {
            $('.header-with-object__text').each(function () {
                let parent = $(this).parents('.header-with-object');
                parent.css('height', $(this).outerHeight() + 'px');
            })
        }
    }

    prepareHeaderWithObjectHeight();

    const trustedMe = function () {
        this.carousel = $('.trusted-me__slider');
        this.init();
    }

    trustedMe.prototype.init = function () {
        if (this.carousel.length > 0) {
            this.carousel.slick({
                slidesToShow: 3,
                slidesToScroll: 1,
                arrows: true,
                dots: false,
                prevArrow: $('.trusted-me__arrow-prev'),
                nextArrow: $('.trusted-me__arrow-next'),
                responsive: [
                    {
                        breakpoint: 768,
                        settings: {
                            centerMode: true,
                            centerPadding: '55px',
                            slidesToShow: 1,
                        }
                    }
                ]
            });
        }
    }

    new trustedMe();

    const ShopTemplateSlider = function () {
        this.carousel = $('.slider-section__items');
        this.init();
    }

    ShopTemplateSlider.prototype.init = function () {
        if (this.carousel) {
            let that = this;

            this.carousel.on('init', function (e, slick) {
                if (slick.$slides.length === 1) {
                    $('.slick-dots', that.carousel).addClass('d-none');
                }
            })

            this.carousel.slick({
                slidesToShow: 1,
                slidesToScroll: 1,
                arrows: false,
                dots: true,
            });
        }
    }
    new ShopTemplateSlider();

    const NewShopPageProducts = function () {
        this.productBoxes = $('.product-boxes');
        this.init();
    }

    NewShopPageProducts.prototype.init = function () {
        if (this.productBoxes && windowWidth <= mobileWidth) {
            let firstBox = this.productBoxes.first();
            let nextBox = firstBox.next();

            $('.product-boxes__items', nextBox).prepend($('.product-boxes__item:nth-child(3)', firstBox))
            $('.product-boxes__item:nth-child(3)', nextBox).addClass('product-boxes__item--full');
        }
    }

    new NewShopPageProducts();

    const ExpertsCarousel = function () {
        this.carousel = $('.wc-acf-experts__slider');
        this.init();
    };

    ExpertsCarousel.prototype.init = function () {
        if (this.carousel.length > 0) {
            this.carousel.slick({
                slidesToShow: 3,
                slidesToScroll: 1,
                arrows: true,
                dots: false,
                centerMode: true,
                centerPadding: '8rem',
                prevArrow: $('.wc-acf-experts__arrow-prev'),
                nextArrow: $('.wc-acf-experts__arrow-next'),
                responsive: [
                    {
                        breakpoint: 768,
                        settings: {
                            prevArrow: false,
                            nextArrow: false,
                            arrows: false,
                            dots: true,
                            centerMode: false,
                            slidesToShow: 1,
                        }
                    }
                ]
            });
        }
    }
    new ExpertsCarousel();

    // check if on the page is element with specific class then add this class to main element
    function ebookOneItemCollectionTemplate() {
        const ebookOneItemClass = 'single-product-page--ebook-one-item-template';

        if ($('.' + ebookOneItemClass).length > 0) {
            $('.site-main').addClass(ebookOneItemClass);
        }
    }

    ebookOneItemCollectionTemplate();

    const LandingFlexibleContentSlider = function () {
        this.carousel = $('.lfc-0-100k-slider__items');
        this.init();
    }

    LandingFlexibleContentSlider.prototype.init = function () {
        if (this.carousel) {
            this.carousel.slick({
                infinite: true,
                slidesToShow: 4,
                slidesToScroll: 1,
                arrows: true,
                dots: false,
                centerMode: true,
                prevArrow: $('.lfc-0-100k-slider__controls__arrow-prev'),
                nextArrow: $('.lfc-0-100k-slider__controls__arrow-next'),
                responsive: [
                    {
                        breakpoint: 768,
                        settings: {
                            arrows: false,
                            slidesToShow: 1,
                        }
                    }
                ]
            });
        }
    }
    new LandingFlexibleContentSlider();

    $('.woocommerce-product-gallery__image a').on('click', function (e) {
        e.stopPropagation();
        e.preventDefault();
    })

    const Banner = function () {
        this.timer = $('.dz-banner-timer');
        this.init();
    }

    Banner.prototype.init = function () {
        this.prepareUrl();
        this.startCountDownDate();
        this.prepareContentMarginTop();
        this.closeButton();
    }

    Banner.prototype.prepareContentMarginTop = function () {
        let banner = $('.dz-banner');

        if (banner.length > 0) {
            $('body').css('margin-top', banner.outerHeight() + 'px');
        } else {
            $('body').css('margin-top', 0);
        }
    }

    Banner.prototype.prepareUrl = function () {
        if (location.href.indexOf('banner_timer') !== -1) {
            window.history.pushState({}, document.title, location.origin + location.pathname);
        }
    }

    Banner.prototype.startCountDownDate = function () {
        const countDownValue = $('input[name="count-down-date-banner"]').val();
        const countDownDate = new Date(countDownValue).getTime();
        const that = this;

        let x = setInterval(function () {
            // Get today's date and time
            let now = new Date().getTime();

            // Find the distance between now and the count down date
            let distance = countDownDate - now;

            // Time calculations for days, hours, minutes and seconds
            let days = Math.floor(distance / (1000 * 60 * 60 * 24));
            let hours = Math.floor((distance % (1000 * 60 * 60 * 24)) / (1000 * 60 * 60));
            let minutes = Math.floor((distance % (1000 * 60 * 60)) / (1000 * 60));
            let seconds = Math.floor((distance % (1000 * 60)) / 1000);

            // If the count down is over, write some text
            if (distance < 0) {
                clearInterval(x);
                $('.dz-banner').remove();

                setTimeout(function () {
                    location.href = location + (location.href.indexOf('?') !== -1 ? '&' : '?') + 'banner_timer=1';
                }, 1000);
            }

            $('.days .timer-box', that.timer).text(days);
            if (days === 1) {
                $('.days .timer-text', that.timer).text('dzień');
            } else {
                $('.days .timer-text', that.timer).text('dni');
            }

            $('.hours', that.timer).text(hours);
            $('.minutes', that.timer).text(minutes);
            $('.seconds', that.timer).text(seconds);
        }, 1000);
    }

    Banner.prototype.closeButton = function () {
        let that = this;

        $('.dz-banner__close').on('click', function () {
            $('.dz-banner').remove();
            that.prepareContentMarginTop();
        })
    }

    new Banner();

    const Popup = function () {
        this.init();
    }

    Popup.prototype.init = function () {
        let popups = $('.dz-popup');
        let that = this;

        if (popups.length > 0) {
            $('body').addClass('contain-popups')

            popups.each(function () {
                let delay = $('input[name="delay"]', this);
                let popup = $(this);

                if (delay.val() === 0) {
                    that.show(popup);
                } else {
                    setTimeout(function () {
                        that.show(popup)
                    }, delay)
                }
            })
        }
    }

    Popup.prototype.show = function (popup) {
        let popupId = popup.attr('id');
        let canBeShown = sessionStorage.getItem(popupId);
        let modal = new bootstrap.Modal(document.getElementById(popupId));

        if (canBeShown === null) {
            modal.show();
            sessionStorage.setItem(popupId, 'true');
        }
    }

    new Popup();

    const ReviewsSlider = function () {
        this.init();
    }

    ReviewsSlider.prototype.init = function () {
        let sliders = $('.figma-reviews--slider .figma-reviews__elements')

        if (sliders.length > 0) {
            sliders.each(function () {
                let parent = $(this).parents('.figma-reviews');

                $(this).slick({
                    slidesToShow: 3,
                    slidesToScroll: 1,
                    arrows: true,
                    dots: true,
                    variableWidth: true,
                    prevArrow: $('.figma-reviews__arrow-prev', parent),
                    nextArrow: $('.figma-reviews__arrow-next', parent),
                    infinite: false,
                    responsive: [
                        {
                            breakpoint: 768,
                            settings: {
                                slidesToShow: 1,
                            }
                        }
                    ]
                });

                $('.figma-reviews__dots', parent).html($('.slick-dots', parent));
            })
        }
    }

    new ReviewsSlider();

    const FigmaGalleryReview = function () {
        this.init();
    }

    FigmaGalleryReview.prototype.init = function () {
        this.loadMore();
    }
    FigmaGalleryReview.prototype.loadMore = function () {
        $('.figma-gallery-review').each(function () {
            let container = $(this);

            $('.show-more', this).on('click', function (e) {
                e.preventDefault();

                let numberOfMessagesToLoad = $('input[name="message-to-load"]', container).val();
                let numberOfMessagesLeftToLoad = $('.gallery-with-msg-section__image.d-none', container).length;
                let imagesToLoad = $('.figma-gallery-review__image.d-none:lt(' + numberOfMessagesToLoad + ')', container);

                if (numberOfMessagesLeftToLoad <= imagesToLoad.length) {
                    $(this).remove();
                }

                imagesToLoad.removeClass('d-none');
            })
        });
    }

    new FigmaGalleryReview();
}(jQuery));
